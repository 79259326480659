import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import PageBannerMap from '../../../components/PageBannerMap/PageBannerMap';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';
import GraphRH from '../../../Molecules/GraphRH/GraphRH';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';

import './styles.scss';

let classNames = require('classnames');

const ImplantationPageFrance = ({ data }) => {
  const implantationData = data?.implantationData.edges[0].node;

  let allSiteArr = [];

  const [allCategoryList] = useState(data?.allSiteData.edges);
  const [allSites, setAllSites] = useState([]);
  const metaTags =  implantationData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
  metaTags.forEach((meta) => {
    if(meta.attributes.name === 'title'){
        metaTitle = meta.attributes.content;
    }
    if(meta.attributes.name === 'description'){
        metaDesc = meta.attributes.content;
    }
  });

  const createAllSitesWithGraphData = (allList) => {
    allList.forEach((site) => {
      if (site.node.field_latitude && site.node.field_longitude) {
        allSiteArr.push({
          city: site.node.field_city || 'Undefined on server',
          title: site.node.title || 'Undefined on server',
          adresse: site.node.field_address,
          email: site.node.field_email,
          phone: site.node.field_telephone,
          website: site.node.field_website,
          code_post: site.node.field_code_postale,
        });
      }
    });
    setAllSites(allSiteArr);
  };

  useEffect(() => {
    createAllSitesWithGraphData(allCategoryList);
  }, [allCategoryList]);

  return (
    <Layout>
      <Seo
        title={implantationData?.field_metatag?.title ? implantationData?.field_metatag?.title : metaTitle}
        description={implantationData?.field_metatag?.description ? implantationData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'implantations_page')}>
        {allSites.length > 0 && (
          <PageBannerMap
            iframeURL="https://colas-implantations.latitude-cartagene.com/?board=false"
            breadcrumbData={{
              parentPage: { title: 'Groupe', url: '' },
              currentPage: {
                title: implantationData.title,
                url:
                  '/' +
                  implantationData.path?.langcode +
                  implantationData.path?.alias,
              },
              locale: implantationData.langcode,
            }}
            returnWordMap={{
              url: implantationData.field_link?.url,
              text: implantationData.field_link?.title
            }}
          >
            <div className="wrapper_page">
              <TitlePage color="color_white" title={implantationData.title} />
              {implantationData.relationships?.field_blocs?.map((block, i) => {
                if (
                  block.__typename === 'block_content__block_chiffres_clefs'
                ) {
                  return (
                    <div key={i}>
                      <div className="col_2">
                        <div className="col graph">
                          <GraphRH
                            text={
                              block.relationships?.field_values[0]?.field_legend
                                ?.processed
                            }
                            jobs={
                              block.relationships?.field_values[0]
                                ?.field_chiffre
                            }
                            textColor="color_white"
                          />
                        </div>
                        <div
                          className="col text"
                          dangerouslySetInnerHTML={{
                            __html: block?.body?.processed,
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </PageBannerMap>
        )}

      </div>
    </Layout>
  );
};

export const query = graphql`
  query implatationFranceTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    implantationData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          path {
            alias
            langcode
          }
          field_link {
            title
	    url
	  }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          field_type
          langcode
          body {
            processed
          }
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_chiffres_clefs {
                  id
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_values {
                      field_chiffre
                      field_legend {
                        processed
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    allSiteData: allNodeNosImplantations(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          field_address
          field_city
          field_code_postale
          field_email
          field_latitude
          field_longitude
          field_telephone
          field_type_etablissement
          field_website
          title
          path {
            alias
            langcode
          }
          langcode
          drupal_id
          field_continent
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel        
              }
            }
          }
          langcode
        }
      }
    }

    file(relativePath: { eq: "slider-image.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`;

export default ImplantationPageFrance;
